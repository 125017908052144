import * as React from 'react'
import theme from '../utils/theme'
import styled from "styled-components"

import Link from "../components/Link"
import Header from '../components/Header'
import Typography from "../components/Typography"
import { Flex, Box } from "../components/Grid"

const StyledFlex = styled(Flex)`
  position: relative;
  overflow: hidden;
  &::before {
    position: absolute;
    content: '';
    top: 0;
    right: -15%;
    transform: translateX(25%) rotate(-45deg);
    background: ${theme.colors.extended.gray200};
    background-size: cover;
    background-repeat: no-repeat;
    width: 800px;
    height: 1800px;
    opacity: 0.5;
    border-radius: 400px;
    z-index: -1;
  }
`

const NotFoundPage = () => {
  return (
    <main>
      <title>Not found</title>
      <Header />
      <StyledFlex height='100vh'>
        <Box px={[3,3,5]} width={['100%','50%']} display='flex' alignItems='center' justifyContent='center'>
          <Box px={0} width='100%'>
            <Typography tag='h1' fontSize={[5,6,7]}>Pagina no encontrada</Typography>
            <Typography tag='p' mb={4}>Lo sentimos, no pudimos encontrar la pagina que estabas buscando, verifica que la dirección URL sea correcta o regresa a nuestra pagina principal</Typography>
            <Link href='/' variant='button-primary'>Volver al inicio</Link>
          </Box>
        </Box>
        <Box width={['100%','50%']} display='flex' alignItems='center' justifyContent='right'>
          <Box px={0} width='100%'>
            <Typography tag='span' fontSize={['120px','180px','240px']} color={theme.colors.secondary.green700}>404</Typography>
          </Box>
        </Box>
      </StyledFlex>
    </main>
  )
}

export default NotFoundPage